import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { FaCogs, FaCheckCircle, FaUsers, FaLightbulb } from 'react-icons/fa'; // Import icons from react-icons
import video from "../components/video/southnetsys.mp4"; // Your local video

const About = () => {
  // Spring animation for section content
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 800 }
  });

  return (
    <section id="about" className="py-20 bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
      <div className="container mx-auto px-6 md:px-12 lg:px-20">
        {/* Header */}
        <div className="text-center mb-12">
          <animated.h1 style={props} className="text-5xl font-extrabold text-white mb-4">About SouthNetSys</animated.h1>
          <animated.p style={props} className="text-xl text-white max-w-3xl mx-auto mb-8">
            Discover our vision, mission, and what makes us the preferred choice for all your tech needs.
          </animated.p>
          
          {/* Introduction Video */}
          <div className="relative mb-12">
            <video 
              className="w-full h-auto rounded-lg shadow-lg" 
              // controls 
              muted 
              autoPlay 
              loop 
              style={{ opacity: 0.8 }}>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        {/* Our Vision Section */}
        <div className="mb-16">
          <h2 className="text-4xl font-bold text-white mb-6">Our Vision</h2>
          <p className="text-lg text-white leading-relaxed max-w-4xl mx-auto">
            We envision a world where technology seamlessly integrates into everyday life, enhancing productivity and connectivity. 
            At SouthNetSys, we are committed to making this vision a reality by delivering exceptional tech solutions and fostering 
            long-lasting relationships with our clients.
          </p>
        </div>

        {/* Why Choose Us Section */}
        <div className="mb-16">
          <h2 className="text-4xl font-bold text-white mb-6">Why Choose Us?</h2>
          <div className="max-w-4xl mx-auto">
            <ul className="space-y-4 text-lg text-white leading-relaxed">
              <li className="flex items-start">
                <span className="inline-block h-10 w-10 mr-3 bg-white text-blue-500 rounded-full flex-shrink-0 flex items-center justify-center">
                  <FaCogs className="text-xl" />
                </span>
                <div>
                  <strong>Expertise:</strong> Our team comprises highly skilled professionals with years of experience in the tech industry.
                </div>
              </li>
              <li className="flex items-start">
                <span className="inline-block h-10 w-10 mr-3 bg-white text-blue-500 rounded-full flex-shrink-0 flex items-center justify-center">
                  <FaCheckCircle className="text-xl" />
                </span>
                <div>
                  <strong>Quality Products:</strong> We source and sell only the highest quality products to ensure longevity and performance.
                </div>
              </li>
              <li className="flex items-start">
                <span className="inline-block h-10 w-10 mr-3 bg-white text-blue-500 rounded-full flex-shrink-0 flex items-center justify-center">
                  <FaUsers className="text-xl" />
                </span>
                <div>
                  <strong>Customer-Centric Approach:</strong> We prioritize our customers, offering personalized services and support to meet their unique needs.
                </div>
              </li>
              <li className="flex items-start">
                <span className="inline-block h-10 w-10 mr-3 bg-white text-blue-500 rounded-full flex-shrink-0 flex items-center justify-center">
                  <FaLightbulb className="text-xl" />
                </span>
                <div>
                  <strong>Innovation:</strong> We stay ahead of the curve by adopting the latest technologies and trends in the IT industry.
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Our Mission Section */}
        <div>
          <h2 className="text-4xl font-bold text-white mb-6">Our Mission</h2>
          <p className="text-lg text-white leading-relaxed max-w-4xl mx-auto">
            At SouthNetSys, our mission is to deliver reliable and innovative technology solutions that empower individuals and businesses 
            to achieve their fullest potential. We strive to offer unparalleled customer service, ensuring that our clients always receive 
            the best possible support and expertise.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
